//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import axios from "axios";
import {maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
import Popup from "@/components/Popup";

export default {
  name: "LegalPerson",
  components: {Popup},
  data(){
    return {
      list: [],
      deletedId: 0,
      newLegal: {
        franchiseId: '',
        legalName: ''
      },
      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      submitted: false,
    }
  },
  props: {
    franchises: Array
  },
  watch: {
    list: function(val){
      this.$emit('updateList', val);
    }
  },
  computed: {

  },
  validations: {
    newLegal: {
      franchiseId: { required, minValue: minValue(1) },
      legalName: { required, minLength: minLength(3), maxLength: maxLength(250) }
    }
  },
  methods: {
    submitNewLegal(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newLegal.$touch();
      if(!this.$v.newLegal.$invalid){
        let formData = new FormData();
        formData.append("franchise_id", this.newLegal.franchiseId);
        formData.append("legal_name", this.newLegal.legalName);

        axios
            .post(`/v1/legal-person/add`, formData)
            .then(resp => {
              this.list = resp.data;
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 2,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    deleteLegal(id){
      this.deletedId = id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    deletePopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedId);

      axios
          .post(`/v1/legal-person/delete`, formData)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.deleted'),
              time: 2,
              color: "success"
            });
            this.getAll();
            this.closeDeletePopup();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      axios
          .get(`/v1/legal-person/get-all`)
          .then(resp => {
            this.list = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getAll();
  }

};
