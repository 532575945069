//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Franchise from "@/components/management/franchise/Franchise";
import LegalPerson from "@/components/management/franchise/LegalPerson";
import LegalRelations from "@/components/management/franchise/LegalRelations";

// import {
//   required,
//   minLength,
//   maxLength
// } from "vuelidate/lib/validators";
// import axios from "axios";

/**
 * Rbac component
 */
export default {
  components: {
    LegalRelations,
    LegalPerson,
    Franchise,
    Layout,
    PageHeader,
  },
  data() {
    return {
      franchiseList: [],
      legalList: [],
      submitted: false,
      title: this.$t('views.franchise.franchise'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.franchise'),
          active: true
        }
      ]
    };
  },
  validations: {

  },
  computed: {

  },
  methods: {
    setFranchiseList(val){
      this.franchiseList = val;
    },
    setLegalList(val){
      this.legalList = val;
    }
  },
  created() {

  }
};
